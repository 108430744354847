import styled, { css, useTheme } from 'styled-components';

type TeaserTilesWrapperProps = {
  hasHeroTile: boolean;
};

export const TeaserTilesWrapper = styled.div(({ hasHeroTile }: TeaserTilesWrapperProps) => {
  const theme = useTheme();

  return css`
    margin: 1.25rem 0;

    ${hasHeroTile &&
    css`
      &.hero-tile-margin {
        margin-top: 1.5rem;
      }
    `}

    @media ${theme.device.large} {
      margin: 5rem 0;
    }

    .centered-col {
      margin: 0 auto;

      h2 {
        margin-bottom: 1.5rem;
      }

      .copy-text {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    .text-wrapper {
      margin-bottom: 1.25rem;

      @media ${theme.device.medium} {
        margin-bottom: 2.5rem;
      }
    }
  `;
});
