import React, { type ReactElement, useState } from 'react';
import { AspectRatio } from '@geberit/gdds';

import { type ImageStyles, type Size } from '../../tile.types';

import {
  ImageWithHeadlineContainer,
  ImageContainer,
  ImageWrapper,
} from './image-with-headline.styles';

// components
import VideoPreviewPlaceholder from 'components/ContentElementsGdds/video/video-preview-placeholder';
import VideoLightbox from 'components/lightbox/video-lightbox';

// utils
import { useUCConsent, VideoConsent } from 'features/cookie-consent';
import { useIsMobile } from 'components/App/SizeProvider';

const aspcetRatios = {
  s: {
    small: '16:9',
    medium: '1:1',
    large: '1:1',
    xlarge: '',
  },
  m: {
    small: '16:9',
    medium: '',
    large: '',
    xlarge: '',
  },
};

interface ImageWithHeadlineProps {
  image: string;
  imageTitle: string;
  imageStyles: ImageStyles;
  size: Size;
  hovered: boolean;
  altText: string;
  breakPoint: string;
  tileHeight: number;
  mediaFormat?: string;
}

export function ImageWithHeadline({
  image,
  imageTitle,
  imageStyles = { size: 'cover', repeat: false },
  size,
  hovered,
  altText,
  breakPoint,
  tileHeight,
  mediaFormat,
  ...item
}: Partial<Omit<ImageWithHeadlineProps, 'size'>> & { size: Size }): ReactElement<any> {
  const ucConsent = useUCConsent();
  const isMobile = useIsMobile();

  // Hint: VideoLightBox must be outside of AspectRatio to show correct.
  const [modalOpen, setModalOpen] = useState(false);

  let renderContent;
  if (mediaFormat === 'video') {
    const isYoutube = item?.video?.type === 'youtube';
    const youtubePlaceholderImage =
      isMobile && item?.placeholderImageObjectMobile?.url
        ? item?.placeholderImageObjectMobile
        : item?.placeholderImageObject;

    // Hint: ucConsent.Comfort can be true,false,undefined, that it will not flicker check on true and false.
    if (ucConsent.Comfort === true) {
      renderContent = (
        <VideoPreviewPlaceholder
          videoPreviewImage={item?.video?.videoPreviewImage}
          youtubePlaceholder={youtubePlaceholderImage as ImageObject}
          playButton={() => setModalOpen(item?.video as VideoProp)}
          className=""
          fullWidthHeight
        />
      );
    } else if (ucConsent.Comfort === false) {
      renderContent = isYoutube ? (
        <VideoConsent type="youtube" fullWidthHeight />
      ) : (
        <VideoConsent type="mi24" fullWidthHeight />
      );
    }
  } else {
    renderContent = image && (
      <ImageContainer className="image-container">
        <ImageWrapper
          title={imageTitle}
          role="img"
          aria-label={altText}
          imageStyles={imageStyles}
          imageSource={image}
        />
      </ImageContainer>
    );
  }

  const ratio = aspcetRatios[size][breakPoint];

  return (
    <ImageWithHeadlineContainer
      className="image-container"
      tileHeight={tileHeight}
      hovered={hovered}
      size={size}
    >
      {ratio ? <AspectRatio ratio={ratio}>{renderContent}</AspectRatio> : renderContent}
      {modalOpen && (
        <VideoLightbox
          onClose={setModalOpen}
          videoId={modalOpen.videoId}
          isYoutubeVideo={modalOpen.type === 'youtube'}
          playerControl={modalOpen.playerControl}
          showLink={modalOpen.showLink}
        />
      )}
    </ImageWithHeadlineContainer>
  );
}
