import { gddsTileColor } from '../../components/ContentElementsGdds/TeaserTilesNordics/types';
import type {
  TileColorProps,
  TileBackgroundColorProps,
} from '../../components/ContentElementsGdds/TeaserTilesNordics/types';
import { THEME_NAMES, ThemeNameProps } from '../../themes';
import type { PossibleThemeName } from './use-theme';

export const getTileColor = (
  color: TileColorProps,
  themeName: PossibleThemeName,
): TileBackgroundColorProps => {
  const greyColors = {
    NORD: ['grey', 'teal', 'blue'],
    KOLO: ['grey', 'green', 'blue'],
    TWYFORD: ['grey', 'teal', 'blue'],
    GDDS: ['grey', 'teal', 'green'],
    DEFAULT: ['grey', 'teal', 'green'],
    XY: ['grey', 'teal', 'green'],
  };

  const isGrey = greyColors[themeName ?? 'DEFAULT']?.includes(color) ?? color === 'grey';
  return isGrey ? gddsTileColor['grey'] : gddsTileColor[color];
};

export const getTileMarker = (marker: string, themeName: ThemeNameProps): string | undefined => {
  const validMarkersForThemes = {
    [THEME_NAMES.DEFAULT]: ['b2b', 'b2c'],
    [THEME_NAMES.GDDS]: ['b2b', 'b2c'],
    [THEME_NAMES.XY]: ['b2b', 'b2c'],
    [THEME_NAMES.NORD]: ['inspireret-af-naturen'],
    [THEME_NAMES.KOLO]: ['inspireret-af-naturen'],
    [THEME_NAMES.TWYFORD]: ['inspireret-af-naturen'],
  };

  return validMarkersForThemes[themeName].includes(marker) ? marker : undefined;
};
