export const gddsSize = {
  nordics_quadratic_tile: {
    type: 'tlea2',
    size: 's',
  },
  nordics_horizontal_tile_large: {
    type: 'tlea3',
    size: 'm',
  },
  nordics_horizontal_tile_normal: {
    type: 'tlea3',
    size: 's',
  },
};

export const gddsTileColor = {
  black: 'black',
  grey: 'light',
  green: 'dark',
  teal: 'dark',
  blue: 'dark',
} as const;

export type TileColorProps = keyof typeof gddsTileColor;
export type TileBackgroundColorProps = (typeof gddsTileColor)[TileColorProps];
