import styled, { css, useTheme } from 'styled-components';
import { styleConfig } from '../../../config';

// types
import type { TileBackgroundColorProps } from './types';

// utils
import { pxToRem } from 'utils/pxToRem';

type LocatorTileWrapperProps = {
  backgroundType: TileBackgroundColorProps;
  showConsent?: boolean;
};

type ColorsMapType = {
  [backgroundType: string]: {
    color: string;
  };
};

export const ShowroomLocatorTileWrapper = styled.div((props: LocatorTileWrapperProps) => {
  const { backgroundType, showConsent } = props;
  const theme = useTheme();

  const colorsMap: ColorsMapType = {
    dark: {
      color: theme.colors.white,
    },
    light: {
      color: theme.colors.black,
    },
    black: {
      color: theme.colors.white,
    },
  };

  return css`
    &&& {
      position: relative;
      color: ${colorsMap[backgroundType]?.color};
      ${showConsent &&
      `
      margin: -2rem;
      height: calc(100% + 4rem);
     `}

      box-sizing: border-box;
      padding: 0;
      outline: none;
      flex: 1 100%;
      box-sizing: border-box;

      @media (min-width: ${pxToRem(styleConfig.responsive.mobileGdds)}) {
        flex: 0 calc(50% - 1.5rem);
      }

      @media ${theme.device.large} {
        flex: 0 calc(33.33% - 1.5rem);
      }

      form {
        border-bottom: 1px solid transparent;
        width: 100%;
        display: block;
        flex-direction: row;
        margin-top: 2rem;

        ul {
          z-index: 1;
          padding: 0.75rem 0 0.5rem 1rem;
          margin-top: 2.75rem;
        }

        ul li p {
          color: black;
        }

        svg {
          fill: ${theme.colors.black};
        }
      }

      position: relative;

      .notification {
        animation: none;
        width: unset;
        position: absolute;
        top: 0rem;
        left: 0rem;
        right: 0rem;
        z-index: 1;
      }

      .copy-text {
        margin-top: 0.75rem;
        font-size: 1rem;
        line-height: 1.5rem;

        @media ${theme.device.large} {
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  `;
});
