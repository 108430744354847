import { useEffect, useRef, useState } from 'react';
import { Link } from '@geberit/gdds';

// styles
import { BorderLayer, StyledDiv, TransparentLayer } from '../common/common.styles';
import { HeadlineWithShortTeaserContainer } from './headline-with-short-teaser.styles';
import styles from '../expressive-tile.module.scss';
import teaserTilesStyles from 'components/ContentElementsGdds/TeaserTilesNordics/teaser-tiles.module.scss';

// types
import type { HeadlineWithShortTeaserProps } from '../../tile.types';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { Image } from 'components/image/image';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { previewId as generatePreviewId } from 'utils/preview-id';
import { useTwyford } from 'utils/hooks/use-twyford';

export function Tlea2({
  title,
  subTitle,
  size,
  paragraph,
  link,
  backgroundType,
  customBackground,
  children,
  gap,
  label,
  marker,
  previewId,
  titlePreviewId,
  subtitlePreviewId,
  textPreviewId,
}: Omit<HeadlineWithShortTeaserProps, 'customBackground'> & {
  customBackground?: string;
}) {
  const [focused, setFocused] = useState(false);
  const [height, setHeight] = useState(0);
  const tileRef = useRef(null);
  const isTwyford = useTwyford();

  function setWidth() {
    if (tileRef.current === null) return;
    const tileWidth = Number(window.getComputedStyle(tileRef.current).width.replace('px', ''));
    setHeight(tileWidth);
  }

  useEffect(() => {
    setWidth();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setWidth);
    return () => window.removeEventListener('resize', setWidth); // cannot test unmount
  }, []);

  let linkStylingType: 'inverted' | 'primary' | 'invertedOnColor' = 'inverted';
  if (backgroundType === 'light') {
    linkStylingType = 'primary';
  } else if (backgroundType === 'dark') {
    linkStylingType = 'invertedOnColor';
  }

  function renderHeadline() {
    const { target, text, showArrow, onClick, windowTarget = 'self' } = link;

    return (
      <>
        <Headline
          tag={Formats.h3}
          title={title}
          subtitle={subTitle}
          text={paragraph as string}
          titlePreviewId={titlePreviewId as `#${string}`}
          subtitlePreviewId={subtitlePreviewId as `#${string}`}
          textPreviewId={textPreviewId as `#${string}`}
          className={classNameBuilder(styles.headline, styles[backgroundType])}
          textClassName={styles.copyText}
          titleClassName={
            !isTwyford
              ? teaserTilesStyles.headlineAndSublineUppercase
              : teaserTilesStyles.twyfordHeadlineAndSubline
          }
          isFlexItem
        />

        {target && text && (
          <Link
            hasArrow={showArrow}
            onClick={(e) => {
              if (typeof onClick === 'function') {
                onClick(e);
              }
            }}
            windowTarget={windowTarget}
            stylingType={linkStylingType}
            text={text}
            target={target}
            alignByContent="left"
            className={styles.link}
          />
        )}
        {marker === 'inspireret-af-naturen' && <StyledDiv>{label}</StyledDiv>}
        {(marker === 'b2b' || marker === 'b2c') && (
          <StyledDiv>
            <Image src={`/icons/icon-${marker}.svg`} alt={marker} staticImage />
          </StyledDiv>
        )}
      </>
    );
  }

  return (
    <HeadlineWithShortTeaserContainer
      ref={tileRef}
      gap={gap}
      height={height}
      onMouseDown={() => setFocused(false)}
      onKeyUp={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      focused={focused}
      tabIndex={0}
      backgroundType={backgroundType}
      customBackground={customBackground}
      size={size}
      data-testid="headline-with-short-teaser-input"
      {...generatePreviewId(previewId)}
    >
      <TransparentLayer />
      {children ?? renderHeadline()}
      <BorderLayer />
    </HeadlineWithShortTeaserContainer>
  );
}
