import { VideoButton } from '@geberit/gdds';

// styles
import styles from './video.module.scss';

// types
import type { VideoPreviewPlaceholderProps } from './video.types';

// components
import { Image } from 'components/image/image';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { VideoConsent, useUCConsent } from 'features/cookie-consent';
import { emptyStringFallback } from 'utils/is-empty';
import { classNameBuilder } from 'utils/classNameBuilder';

export default function VideoPreviewPlaceholder({
  videoPreviewImage,
  youtubePlaceholder,
  playButton,
  fullWidthHeight,
  className,
}: Readonly<VideoPreviewPlaceholderProps>) {
  const isMobile = useIsMobile();
  const videoImage = isMobile
    ? videoPreviewImage?.mobile
    : emptyStringFallback(videoPreviewImage?.desktop, videoPreviewImage?.mobile);
  const consent = useUCConsent();

  return (
    <div className={classNameBuilder(styles.previewImageWrapper, fullWidthHeight && styles.videoPreviewFullWidthHeight, className)}>
      {consent.Comfort ? (
        <>
          <div
            className={classNameBuilder(styles.videoPreviewImage, fullWidthHeight && styles.videoPreviewImageFullWidthHeight)}
            style={{
              backgroundImage: `url(${videoImage})`,
            }}
          >
            {youtubePlaceholder && <Image data={youtubePlaceholder} />}
          </div>
          <VideoButton onClick={playButton} />
        </>
      ) : (
        <div className={styles.consent}>
          <VideoConsent type="mi24" inTile={false} />
        </div>
      )}
    </div>
  );
}
