import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Form, FormField, Button } from '@geberit/gdds';

// types
import type { TileBackgroundColorProps } from './types';

// constants
import { Formats } from '../headline/headlines.types';

// styles
import { LocatorTileWrapper } from './locator-tile-wrapper.styles';
import teaserTilesStyles from 'components/ContentElementsGdds/TeaserTilesNordics/teaser-tiles.module.scss';

// components
import { Headline } from '../headline/headline';
import { locatorTileSubmit } from 'components/ContentElements/teaser/tracking-actions';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { decodingContent } from 'utils/decodingContent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { previewId as generatePreviewId } from 'utils/preview-id';
import { isEmpty } from 'utils/is-empty';
import { useTwyford } from 'utils/hooks/use-twyford';

interface LocatorTileProps {
  backgroundType: TileBackgroundColorProps;
  searchPlaceholder?: string;
  title?: string;
  color?: string;
  subtitle?: string;
  link?: Link;
  text?: string;
  previewId?: string;
  titlePreviewId?: `#${string}`;
  subtitlePreviewId?: `#${string}`;
  textPreviewId?: `#${string}`;
  reset?: (...args: unknown[]) => void;
}

function LocatorTile({ backgroundType, ...props }: Readonly<LocatorTileProps>) {
  const {
    title,
    subtitle,
    text,
    link,
    searchPlaceholder,
    previewId,
    titlePreviewId,
    subtitlePreviewId,
    textPreviewId,
    reset,
  } = props;
  const track = useTracking();
  const router = useRouter();
  const isTwyford = useTwyford();
  const [locationValue, setLocationValue] = useState();
  const [error, setError] = useState('');
  const cutText = (paragraph) =>
    paragraph.length >= 140 ? `${paragraph.slice(0, 140)}…` : paragraph;

  const onSubmit = (e) => {
    e.preventDefault();

    if (isEmpty(locationValue)) {
      setError('postcode empty!');
    } else if (link?.target) {
      track.trackEvent(locatorTileSubmit(locationValue));
      router.push(`${link.target}?location=${locationValue}`);
    }
  };

  const onChange = (e) => {
    if (error) {
      setError('');
    }
    const target = e.currentTarget.value;
    setLocationValue(target);
  };

  return (
    <LocatorTileWrapper
      backgroundType={backgroundType}
      {...generatePreviewId(previewId)}
      tabIndex={0}
    >
      <div className="background-hover"></div>
      <Headline
        tag={Formats.h3}
        title={decodingContent(title)}
        subtitle={decodingContent(subtitle)}
        text={cutText(text)}
        titlePreviewId={titlePreviewId}
        subtitlePreviewId={subtitlePreviewId}
        textPreviewId={textPreviewId}
        textClassName="copy-text"
        titleClassName={
          !isTwyford
            ? teaserTilesStyles.headlineAndSublineUppercase
            : teaserTilesStyles.twyfordHeadlineAndSubline
        }
        isFlexItem
      />

      <div className={classNameBuilder('search-input', error && 'error')}>
        <Form onSubmit={onSubmit}>
          <FormField
            name="postcode"
            placeholder={decodingContent(searchPlaceholder)}
            values={locationValue}
            reset={() => {
              if (typeof reset === 'function') {
                reset('postcode');
              }
            }}
            onChange={onChange}
          />
          <Button buttonType="submit" stylingType="flatInverted" symbol="Search" isIcon />
        </Form>
        {error && <span className={error ? 'error' : 'valid'}>Please enter postcode</span>}
      </div>
    </LocatorTileWrapper>
  );
}

export default LocatorTile;
