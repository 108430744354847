import { useContext } from 'react';
import { Notification } from '@geberit/gdds';

// constants
import { Formats } from '../headline/headlines.types';

// styles
import teaserTilesStyles from 'components/ContentElementsGdds/TeaserTilesNordics/teaser-tiles.module.scss';

// components
import { Headline } from '../headline/headline';
import { GoogleMapProvider } from '../locator/overview-page/google-map-provider';
import { SearchContainer, SearchContext } from '../locator/search';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useUCConsent } from 'features/cookie-consent';
import { useLocatorConfiguration } from '../locator/utils/use-locator-configuration';
import { useTwyford } from 'utils/hooks/use-twyford';

const cutText = (paragraph: string) =>
  paragraph.length >= 140 ? `${paragraph.slice(0, 140)}…` : paragraph;

export interface ShowroomLocatorTileProps {
  searchPlaceholder?: string;
  title?: string;
  color?: string;
  subtitle?: string;
  link?: Link;
  text?: string;
  previewId?: string;
  titlePreviewId?: string;
  subtitlePreviewId?: string;
  textPreviewId?: string;
  reset?: (...args: unknown[]) => void;
}

export function ShowroomLocatorTileContent(
  props: Readonly<Omit<ShowroomLocatorTileProps, 'backgroundType'>>,
) {
  const { title, subtitle, text, titlePreviewId, subtitlePreviewId, textPreviewId } = props;
  const consent = useUCConsent();
  const isTwyford = useTwyford();
  const { error, resetError } = useContext(SearchContext);
  const showInputField = consent.Comfort === true;
  const locatorConfiguration = useLocatorConfiguration();

  return (
    <div>
      <Headline
        tag={Formats.h3}
        title={decodingContent(title)}
        subtitle={decodingContent(subtitle)}
        text={cutText(text ?? '')}
        titlePreviewId={titlePreviewId as `#${string}`}
        subtitlePreviewId={subtitlePreviewId as `#${string}`}
        textPreviewId={textPreviewId as `#${string}`}
        textClassName="copy-text"
        titleClassName={
          !isTwyford
            ? teaserTilesStyles.headlineAndSublineUppercase
            : teaserTilesStyles.twyfordHeadlineAndSubline
        }
        isFlexItem
      />

      <GoogleMapProvider>{showInputField && <SearchContainer />}</GoogleMapProvider>
      {error && (
        <Notification
          type="broadcast"
          text={locatorConfiguration?.consent?.geoLocationFailureNote ?? ''}
          buttonStyleType="flatInverted"
          showCloseButton
          positionTop={0}
          onClick={resetError}
          className="notification"
        />
      )}
    </div>
  );
}
