import { Link } from '@geberit/gdds';

// types
import type { HeadlineWithShortTeaserProps } from '../../tile.types';

// styles
import { HeadlineWithShortTeaserContainer } from './headline-with-short-teaser.styles';
import { StyledDiv, TransparentLayer } from '../common/common.styles';
import styles from '../expressive-tile.module.scss';
import teaserTilesStyles from 'components/ContentElementsGdds/TeaserTilesNordics/teaser-tiles.module.scss';

// constants
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { Image } from 'components/image/image';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTwyford } from 'utils/hooks/use-twyford';

export function HeadlineWithShortTeaser(
  props: Omit<HeadlineWithShortTeaserProps, 'customBackground'> & {
    hovered?: boolean;
    customBackground?: string;
  },
) {
  const {
    title,
    subTitle,
    size,
    paragraph,
    link,
    backgroundType,
    customBackground,
    hovered,
    children,
    tileHeight,
    label,
    marker,
    titlePreviewId,
    subtitlePreviewId,
    textPreviewId,
  } = props;

  return (
    <HeadlineWithShortTeaserContainer
      className="headline-container"
      backgroundType={backgroundType}
      customBackground={customBackground}
      size={size}
      hovered={hovered}
      tileHeight={tileHeight}
    >
      <TransparentLayer />
      {children || (
        <Content
          title={title}
          subTitle={subTitle}
          paragraph={paragraph}
          link={link}
          backgroundType={backgroundType}
          label={label}
          marker={marker}
          titlePreviewId={titlePreviewId}
          subtitlePreviewId={subtitlePreviewId}
          textPreviewId={textPreviewId}
        />
      )}
    </HeadlineWithShortTeaserContainer>
  );
}

type ContentProps = Omit<
  HeadlineWithShortTeaserProps,
  'customBackground' | 'size' | 'type' | 'gap' | 'tileHeight'
>;

function Content(props: Readonly<ContentProps>) {
  const {
    title,
    subTitle,
    paragraph,
    link: { target, text, showArrow, onClick, windowTarget },
    backgroundType,
    label,
    marker,
    titlePreviewId,
    subtitlePreviewId,
    textPreviewId,
  } = props;

  const isTwyford = useTwyford();
  let stylingType: 'inverted' | 'primary' | 'invertedOnColor' = 'inverted';
  if (backgroundType === 'light') {
    stylingType = 'primary';
  } else if (backgroundType === 'dark') {
    stylingType = 'invertedOnColor';
  }

  return (
    <>
      <Headline
        tag={Formats.h3}
        title={title}
        subtitle={subTitle}
        text={paragraph as string}
        titlePreviewId={titlePreviewId as `#${string}`}
        subtitlePreviewId={subtitlePreviewId as `#${string}`}
        textPreviewId={textPreviewId as `#${string}`}
        className={classNameBuilder(styles.headline, styles[backgroundType])}
        textClassName={styles.copyText}
        titleClassName={
          !isTwyford
            ? teaserTilesStyles.headlineAndSublineUppercase
            : teaserTilesStyles.twyfordHeadlineAndSubline
        }
        isFlexItem
      />

      {!!target && !!text && (
        <Link
          hasArrow={showArrow}
          onClick={(e) => {
            if (typeof onClick === 'function') {
              onClick(e);
            }
          }}
          windowTarget={windowTarget}
          stylingType={stylingType}
          text={text}
          target={target}
          alignByContent="left"
          className={styles.link}
        />
      )}

      {marker === 'inspireret-af-naturen' && <StyledDiv>{label}</StyledDiv>}

      {(marker === 'b2b' || marker === 'b2c') && (
        <StyledDiv>
          <Image src={`/icons/icon-${marker}.svg`} alt={marker} staticImage />
        </StyledDiv>
      )}
    </>
  );
}
