import React, {
  ReactElement,
  FC,
  useState,
  ReactNode,
  Children,
  isValidElement,
  cloneElement,
  useEffect,
} from 'react';
import { useTheme } from 'styled-components';
import { useMedia, ThemeType as Theme } from '@geberit/gdds';

// types
import { TilesProps } from './tiles.types';

// styles
import { TilesContainer } from './tiles.styles';

export const Tiles: FC<TilesProps> = ({
  children,
  type,
  onSelect,
  gap = '1.5rem',
  initialSelectedIndex = 0,
  previewId,
  previewIdExtractor,
}): ReactElement<any> => {
  const theme = useTheme() as Theme;
  const [selectedTileIndex, setSelectedTileIndex] = useState(initialSelectedIndex);
  const breakPoint = useMedia<'xlarge' | 'large' | 'medium' | 'small'>(
    [theme.device.xlarge, theme.device.large, theme.device.medium, theme.device.small],
    ['xlarge', 'large', 'medium', 'small'],
    'large',
  );

  const handleOnSelect = (index: number) => {
    setSelectedTileIndex(index);
    if (onSelect) {
      onSelect(index);
    }
  };

  useEffect(() => {
    setSelectedTileIndex(initialSelectedIndex);
  }, [initialSelectedIndex]);

  const results = Children.map<ReactNode, ReactNode>(children, (child, index) => {
    if (isValidElement(child)) {
      const props = {
        key: String(index),
        gap,
        breakPoint,
        selected: false,
        onSelect: () => {},
        'data-preview-id':
          typeof previewIdExtractor === 'function' ? previewIdExtractor(child, index) : undefined,
      };
      if (type === 'productive') {
        props.selected = index === selectedTileIndex;
        props.onSelect = () => handleOnSelect(index);
      }
      return cloneElement(child, props);
    }
    return null;
  });

  return (
    <TilesContainer data-preview-id={previewId} gap={gap} type={type}>
      {results?.filter(Boolean)}
    </TilesContainer>
  );
};
