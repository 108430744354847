import styled from 'styled-components';

export const TransparentLayer = styled.div`
  &&& {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export const BorderLayer = styled.div`
  &&& {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    border: 1px solid transparent;
    pointer-events: none;
  }
`;

export const StyledDiv = styled.div`
  margin: 1.5rem 0 1rem -1.5rem;
  display: block;
  width: 100%;
  position: relative;

  svg {
    @media ${({ theme }) => theme.device.medium} {
      position: absolute;
      bottom: 2.5rem;
      left: 0;
    }
    @media ${({ theme }) => theme.device.large} {
      bottom: 1.875rem;
    }

    @media ${({ theme }) => theme.device.xlarge} {
      bottom: 2.5rem;
    }
  }

  @media ${({ theme }) => theme.device.medium} {
    position: unset;
  }

  img {
    width: 3.75rem;
    height: 3.75rem;
    left: 2rem;
    bottom: 1.5rem;

    @media ${({ theme }) => theme.device.small} {
      margin: 0 0 -0.75rem 1.5rem;
    }

    @media ${({ theme }) => theme.device.medium} {
      position: absolute;
      margin: 0;
    }

    @media ${({ theme }) => theme.device.xlarge} {
      bottom: 2rem;
    }
  }
`;
