import key from 'weak-key';
import { useRouter } from 'next/navigation';

// components
import LocatorTile from './locator-tile';
import Label from './label';
import { ExpressiveTile } from 'components/ContentElementsGdds/CustomGdds/Tiles/src/tile/expressive/expressive-tile';
import { ShowroomLocatorTile } from './showroom-locator-tile';
import { Tlea3 } from 'components/ContentElementsGdds/CustomGdds/Tiles/src/tile/expressive/Tlea3/tlea3';
import { Tlea2 } from 'components/ContentElementsGdds/CustomGdds/Tiles/src/tile/expressive/Tlea2/tlea2';

// utils
import { isEmpty } from 'utils/is-empty';
import { gddsSize } from './types';
import { decodingContent } from 'utils/decodingContent';
import { useTracking } from 'utils/hooks/useTracking';
import { handlePopup } from 'utils/openInPopup';
import { getTileColor, getTileMarker } from 'utils/hooks/get-tile-color';
import { useThemeName } from 'utils/hooks/use-theme';
import { useKoloOrTwyford } from 'utils/hooks/use-kolo-twyford';
import { clickTileLink } from 'components/ContentElements/teaser/tracking-actions';
import { popUpLink } from 'components/Link/trackingActions';

function TileLoader({ ...item }: TeaserTile & { tileIndex: number; imageAlt: string }) {
  const {
    color,
    title,
    subtitle,
    link,
    text,
    marker,
    type,
    imageAlt,
    tileIndex,
    mediaType,
    mediaFormat,
  } = item;
  const imageSize = mediaType === 'large' ? 'm' : 's';
  const track = useTracking();
  const linkData: Link = !isEmpty(link) ? { ...link, windowTarget: link.window } : {};
  const router = useRouter();

  const themeName = useThemeName();
  const isKoloOrTwyford = useKoloOrTwyford();
  const tileColor = getTileColor(color, themeName);
  const tileMarker = getTileMarker(marker, themeName);

  const onTileClick = (e) => {
    e.preventDefault();

    // popup
    if (linkData.window === '_popup') {
      const windowId = key(linkData);
      track.trackEvent(popUpLink(linkData?.target));
      return handlePopup(linkData, windowId);
    }

    // internal Link
    if (linkData?.type === 'internal_link') {
      if (linkData?.window === '_blank') {
        window.open(linkData?.target);
      } else {
        router.push(linkData?.target);
      }

      // external Link
    } else if (linkData.window === '_self') {
      window.location.href = linkData?.target;
    } else {
      window.open(linkData?.target, '_blank');
    }

    track.trackEvent(clickTileLink(window.location.href, linkData?.target));
  };

  if (type === 'nordics_locator_tile') {
    return (
      <LocatorTile
        titlePreviewId="#st_title"
        subtitlePreviewId="#st_subtitle"
        textPreviewId="#st_text"
        backgroundType={tileColor}
        {...item}
        previewId={`#${tileIndex}`}
      />
    );
  }

  if (type === 'showroom_locator_tile_nordics') {
    const commonProps = {
      label:
        marker === 'inspireret-af-naturen' && !isKoloOrTwyford ? (
          <Label border={tileColor === 'dark'} />
        ) : undefined,
      imageTitle: decodingContent(imageAlt, true),
      altText: decodingContent(imageAlt, true),
      size: imageSize,
      imageSize,
      backgroundType: tileColor,
      fontSize: 'p1',
      previewId: `#${tileIndex}`,
      children: (
        <ShowroomLocatorTile
          titlePreviewId="#st_title"
          subtitlePreviewId="#st_subtitle"
          textPreviewId="#st_text"
          backgroundType={tileColor}
          {...item}
        />
      ),
    };
    // Special case: Also use for video the Tlea3, Tlea2 only shows text no image or video.
    if (item.image || mediaFormat === 'video') {
      return <Tlea3 {...item} {...commonProps} />;
    }
    return <Tlea2 {...item} {...commonProps} />;
  }

  return (
    <ExpressiveTile
      {...item}
      link={{ ...link, onClick: onTileClick, target: link?.target }}
      title={decodingContent(title, true)}
      subTitle={decodingContent(subtitle, true)}
      label={
        marker === 'inspireret-af-naturen' && !isKoloOrTwyford ? (
          <Label border={tileColor === 'dark'} />
        ) : undefined
      }
      marker={tileMarker}
      imageTitle={decodingContent(imageAlt, true)}
      altText={decodingContent(imageAlt, true)}
      type={gddsSize[type].type}
      size={gddsSize[type].size}
      mediaFormat={mediaFormat}
      imageSize={gddsSize[type].size}
      paragraph={decodingContent(text, true)}
      backgroundType={tileColor}
      fontSize="p1"
      previewId={`#${tileIndex}`}
      titlePreviewId="#st_title"
      subtitlePreviewId="#st_subtitle"
      textPreviewId="#st_text"
    />
  );
}

export default TileLoader;
