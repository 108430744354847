import { useState, useEffect, useRef, useCallback } from 'react';

// types
import { ImageWithHeadlineAndTeaserProps } from '../../tile.types';

// components
import { HeadlineWithShortTeaser } from './headline-with-short-teaser';
import { ImageWithHeadline } from './image-with-headline';

// styles
import { ImageWithHeadlineAndTeaserContainer } from './image-with-headline-and-teaser.styles';
import { BorderLayer } from '../common/common.styles';

// utils
import { previewId as generatePreviewId } from 'utils/preview-id';

export function Tlea3({
  title,
  subTitle,
  image,
  imageTitle,
  imageSize,
  backgroundType,
  customBackground,
  paragraph,
  link,
  imagePosition,
  children,
  altText,
  emphasize = 'title',
  fontSize,
  gap,
  breakPoint,
  imageStyles,
  label,
  marker,
  previewId,
  titlePreviewId,
  subtitlePreviewId,
  textPreviewId,
  mediaFormat,
  ...item
}: Readonly<
  Omit<ImageWithHeadlineAndTeaserProps, 'customBackground'> & {
    customBackground?: string;
  }
>) {
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [tileHeight, setTileHeight] = useState(0);
  const tileRef = useRef(null);

  const setContainerHeight = useCallback(() => {
    if (tileRef.current === null) {
      return;
    }
    const tilesWidth = Number(window.getComputedStyle(tileRef.current).width.replace('px', ''));

    switch (breakPoint) {
      case 'large':
      case 'xlarge': {
        const tilesCount = 3;
        const gapSize = Number(gap.replace('rem', '')) * 16 * (imageSize === 'm' ? 2 : 1);
        const heightL = (tilesWidth - gapSize) / (tilesCount - (imageSize === 'm' ? 0 : 1));
        setTileHeight(heightL);
        break;
      }
      case 'medium': {
        const tilesCount = 2;
        const gapSize = Number(gap.replace('rem', '')) * 16;
        const heightM = (tilesWidth - gapSize) / tilesCount;
        setTileHeight(heightM);
        break;
      }
      default:
        break;
    }
  }, [breakPoint, gap, imageSize]);

  useEffect(() => {
    setContainerHeight();
  }, [breakPoint, setContainerHeight]);

  useEffect(() => {
    window.addEventListener('resize', setContainerHeight);
    return () => window.removeEventListener('resize', setContainerHeight);
  }, [breakPoint, setContainerHeight]);

  return (
    <ImageWithHeadlineAndTeaserContainer
      ref={tileRef}
      imagePosition={imagePosition}
      onMouseDown={() => setFocused(false)}
      onKeyUp={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      focused={focused}
      tabIndex={0}
      size={imageSize === 's' ? 'm' : 'l'}
      backgroundType={backgroundType}
      data-testid="image-with-headline-and-teaser-input"
      customBackground={customBackground}
      {...generatePreviewId(previewId)}
    >
      <ImageWithHeadline
        {...item}
        breakPoint={breakPoint}
        tileHeight={tileHeight}
        hovered={hovered}
        image={image}
        imageTitle={imageTitle}
        size={imageSize}
        altText={altText}
        imageStyles={imageStyles}
        mediaFormat={mediaFormat}
      />
      <HeadlineWithShortTeaser
        hovered={hovered}
        tileHeight={tileHeight}
        backgroundType={backgroundType}
        customBackground={customBackground}
        type="tlea2"
        title={title}
        subTitle={subTitle}
        size="s"
        link={link}
        fontSize={fontSize}
        paragraph={paragraph}
        emphasize={emphasize}
        label={label}
        marker={marker}
        gap={gap}
        titlePreviewId={titlePreviewId}
        subtitlePreviewId={subtitlePreviewId}
        textPreviewId={textPreviewId}
      >
        {children}
      </HeadlineWithShortTeaser>
      <BorderLayer className="border-layer" />
    </ImageWithHeadlineAndTeaserContainer>
  );
}
