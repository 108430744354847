import styled from 'styled-components';
import type { ThemeType as Theme } from '@geberit/gdds';

import { BackgroundType, Size, States } from '../../tile.types';
import { TransparentLayer } from '../common/common.styles';
import { getTransparentLayerDeclarations } from '../common/helpers';

interface HeadlineWithShortTeaserProps {
  size: Size;
  backgroundType: BackgroundType;
  customBackground?: string;
  hovered?: boolean;
  focused?: boolean;
  tileHeight?: number;
}

const sizeMap = {
  small: {
    s: '1 100%',
  },
  medium: {
    s: '0 calc(50% - 12px)',
  },
  large: {
    s: '0 calc(33.33% - 16px)',
  },
};

const getDeclarations = (
  theme: Theme,
  background: BackgroundType,
  customBackground: string | undefined,
) => {
  const colorsMap = {
    black: {
      normal: theme.colors.black,
      transparent: theme.colors.transparentBlack,
    },
    primary: {
      normal: theme.colors.primaryLight,
      transparent: theme.colors.transparentPrimaryLight,
    },
    primaryDark: {
      normal: theme.colors.primaryDark,
    },
    white: {
      normal: theme.colors.white,
      transparent: theme.colors.transparentWhite,
    },
  };

  const styles: { backgroudColor: string; backgroundOpacity: string | undefined } = {
    backgroudColor: 'black',
    backgroundOpacity: '0.06',
  };

  if (background === 'dark') {
    styles.backgroudColor = 'primary';
    styles.backgroundOpacity = undefined;
  }

  if (background === 'black') {
    styles.backgroudColor = 'black';
    styles.backgroundOpacity = undefined;
  }

  const deklarations: Array<string> = [];
  if (styles.backgroundOpacity && !customBackground) {
    deklarations.push(
      `background: rgb(${colorsMap[styles.backgroudColor].transparent}, ${
        styles.backgroundOpacity
      });`,
    );
  } else if (customBackground) {
    deklarations.push(`background: ${customBackground};`);
  } else {
    deklarations.push(`background: ${colorsMap[styles.backgroudColor].normal};`);
  }

  return deklarations;
};

export const HeadlineWithShortTeaserContainer = styled.div<HeadlineWithShortTeaserProps>`
  ${({ size }) => `flex: ${sizeMap.small[size]};`}
  ${({ theme, backgroundType, customBackground }) =>
    getDeclarations(theme, backgroundType, customBackground)}
  & ${TransparentLayer} {
    ${({ theme, backgroundType }) => getTransparentLayerDeclarations(theme, backgroundType)}
  }

  outline: none;
  position: relative;
  min-height: 13.5rem;
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 1rem 1.5rem;

  & .image-container {
    transition: all 0.25s ease-in-out;
  }

  ${({ theme, backgroundType, customBackground }) =>
    getDeclarations(theme, backgroundType, customBackground)}
  & ${TransparentLayer} {
    ${({ theme, backgroundType, hovered }) =>
      hovered ? getTransparentLayerDeclarations(theme, backgroundType, 'hovered' as States) : ''}
  }

  & .image-container {
    ${({ hovered }) => (hovered ? 'transform: scale(1);' : '')}
  }

  @media ${({ theme }) => theme.device.medium} {
    ${({ size }) => `flex: ${sizeMap.medium[size]};`}
    padding: 2rem;
    ${({ tileHeight }) => `height: ${tileHeight}px;`}
  }

  @media ${({ theme }) => theme.device.large} {
    ${({ size }) => `flex: ${sizeMap.large[size]};`}
  }

  @media ${({ theme }) => theme.device.xlarge} {
    max-height: 21.75rem;
  }
`;
